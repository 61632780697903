import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import FormNavigation from 'components/FormNavigation';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { YourNameInputLabel } from 'components/LoanForm/YourName/YourName';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';

import { ReactComponent as CardFlipEllipse } from 'images/card-flip-ellipse.svg';
import { ReactComponent as HandPointing } from 'images/hand-pointing.svg';
import { setAboutYouData } from 'handlers/yourName';
import { getYourNameData } from 'selectors/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { getMessageForRequiredFields } from 'utils/errors';
import useLayoutTheme from 'hooks/useLayoutTheme';
import useRotateCard from 'hooks/useRotateCard';
import { getCardData } from 'selectors/getCardData';
import { CardDataVariable, setCardData } from 'handlers/cardData';
import { FlowComponentType } from 'routes/FlowRouter';
import { useQueryParams } from 'hooks/useQueryParam';
import { setSelectedTheme } from 'handlers/theme';
import useScreenSize from 'hooks/useScreenSize';

import { Card, CardColor, cards, colorSets } from './cardVersions';

import styles from './Customize.module.scss';

const Customize = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const colorSet = colorSets[params.get('cardVersion') as keyof typeof colorSets] || colorSets[1];

  const dispatch = useDispatch();

  const { cardColor } = useSelector(getCardData);
  const [selectedColor, setSelectedColor] = useState<CardColor>(cardColor ?? colorSet[0]);
  const { theme, setTheme } = useLayoutTheme();
  const { isMobile } = useScreenSize();

  const { cardRef: flipCardRef } = useRotateCard();

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { borrowerCredentials } = useSelector(getCardData);

  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      credentials: borrowerCredentials ?? '',
    },
  });

  const watcher = watch();

  const shouldAddComma = (watcher.first_name || watcher.last_name) && watcher.credentials;
  const cardText = `${watcher.first_name} ${watcher.last_name}${shouldAddComma ? ',' : ''} ${watcher.credentials}`;

  const onCardClick = (card: CardColor) => {
    setSelectedColor(card);
    setTheme(cards[card].theme);
    dispatch(setSelectedTheme(cards[card].theme));
    analytics.track('Card Color Selected', { color: selectedColor });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value.trim());
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value);
    trigger(event.target.name as AboutYouVariable);
  };

  const onNext = () => {
    dispatch(setAboutYouData(watcher));
    dispatch(setCardData({ cardColor: selectedColor, borrowerCredentials: watcher.credentials }));
    handleNext();
  };

  useEffect(() => {
    register(AboutYouVariable.FirstName, { required: getMessageForRequiredFields(YourNameInputLabel.FirstName) });
    register(AboutYouVariable.LastName, { required: getMessageForRequiredFields(YourNameInputLabel.LastName) });
    register(CardDataVariable.Credentials, { required: getMessageForRequiredFields(YourNameInputLabel.Credentials) });
  }, []);

  const selectedCard = cards[selectedColor];

  const displayCardByColor = (color: CardColor, side: string) => {
    const pickedCard = cards[color];

    if (side === 'front') {
      return <pickedCard.front />;
    }
    return <pickedCard.back />;
  };

  const displayCardPreview = (card: Card, side: string) => {
    if (side === 'front') {
      return <card.front />;
    }
    return <card.back />;
  };

  useEffect(() => {
    setTheme(selectedCard.theme);
  }, []);

  return (
    <div className={styles.container}>
      <FormNavigation
        className={styles.formNavigation}
        {...{ ...navigationInfo, title: !isMobile ? 'Customize Your Card' : navigationInfo.title }}
        titleAsHeading={!isMobile}
      />
      <FormContainer className={styles.formContainer} title={isMobile ? 'Customize Your Card' : undefined}>
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <div className={styles.leftContent}>
              <div className={clsx(styles.inputs, styles[`inputs__${theme}`])}>
                <Input
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  errorMessage={errors[AboutYouVariable.FirstName]?.message}
                  label="First name"
                  placeholder="First Name"
                  name={AboutYouVariable.FirstName}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={watcher[AboutYouVariable.FirstName]}
                  data-neuro-label="firstName"
                />
                <Input
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  errorMessage={errors[AboutYouVariable.LastName]?.message}
                  label="Last name"
                  placeholder="Last Name"
                  name={AboutYouVariable.LastName}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={watcher[AboutYouVariable.LastName]}
                  data-neuro-label="lastName"
                />
                <Input
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  errorMessage={errors[CardDataVariable.Credentials]?.message}
                  label="Credentials"
                  placeholder="RN, CNA, LPN, etc."
                  name={CardDataVariable.Credentials}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={watcher[CardDataVariable.Credentials]}
                  data-neuro-label="title"
                />
              </div>

              <div className={styles.cardPickerContainer}>
                <p className={styles.label}>Select color</p>
                <div className={styles.cardPickerItems}>
                  {Object.values(colorSet).map((color, index) => (
                    <div
                      key={`${color}-${index}`}
                      className={clsx(styles.cardItem, styles[`cardItem__${theme}`])}
                      onClick={() => onCardClick(color)}
                    >
                      <div
                        className={clsx(styles.card, styles[`card__${theme}`], {
                          [styles.selected]: selectedColor === color,
                        })}
                      >
                        {displayCardByColor(color, 'front')}
                      </div>
                      <p className={styles.cardColor}>{cards[color].name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.previewContainer}>
              <div className={styles.cardPreview}>
                <div className={styles.flipCardBackground} />
                <div className={clsx(styles.ellipseContainer, styles[theme])}>
                  <CardFlipEllipse className={styles.ellipse} />
                  <div className={styles.handPointingContainer}>
                    <HandPointing className={styles.handPointing} />
                  </div>
                </div>
                <div className={styles.flipCard}>
                  <div ref={flipCardRef} className={clsx(styles.flipCardInner)}>
                    <div className={styles.flipCardFront}>
                      {displayCardPreview(selectedCard, 'front')}
                      <p className={clsx(styles.cardOwnerName, styles[selectedColor.toLowerCase()], styles.frontName)}>
                        {cardText}
                      </p>
                    </div>
                    <div className={styles.flipCardBack}>
                      {displayCardPreview(selectedCard, 'back')}
                      <p className={clsx(styles.cardOwnerName, styles[selectedColor.toLowerCase()], styles.backName)}>
                        {cardText}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button disabled={!isValid} onClick={onNext} className={styles.button}>
              Next
            </Button>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

export default Customize;
