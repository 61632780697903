import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCardData } from 'selectors/getCardData';
import { ReactComponent as CardAppliedIcon } from 'images/card-applied.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { formatNumber } from 'utils/formatNumber';
import ShareModal, { SHARE_MESSAGE } from 'components/ShareModal/ShareModal';
import useLayoutTheme from 'hooks/useLayoutTheme';
import clsx from 'clsx';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './CardApplied.module.scss';

const CardApplied = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const { themeStyles, theme } = useLayoutTheme();

  const handleCloseModal = () => {
    handleNext();
  };

  const onShare = () => {
    setShowShareModal(true);
    analytics.track('Card CTA Share Button Clicked');
  };

  const { referralLink, waitListPosition } = useSelector(getCardData);

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={clsx(styles.iconContainer, styles[`icon__${theme}`])}>
          <CardAppliedIcon />
        </div>
        <div className={styles.title}>
          You're number #
          {waitListPosition ? <span className={styles.bold}>{formatNumber(waitListPosition)}</span> : <></>} on the
          Waitlist!
        </div>
        <div className={clsx(styles.description, themeStyles.text)}>
          We’ll keep you updated as we make progress towards launch.
        </div>
        <div className={clsx(styles.description, themeStyles.text)}>
          There are{' '}
          {waitListPosition ? <span className={styles.bold}>{formatNumber(waitListPosition - 1)}</span> : <></>} people
          ahead of you. We’re prioritizing applicants who refer someone else who applies.
        </div>
        {referralLink && (
          <Button className={styles.shareButton} onClick={onShare}>
            Share and move to the front!
          </Button>
        )}
        <Button type={ButtonType.Inverted} onClick={handleNext}>
          Not Now
        </Button>
      </div>
      {showShareModal && (
        <ShareModal
          shareMessage={SHARE_MESSAGE}
          handleClose={handleCloseModal}
          referralLink={referralLink || 'https://planneryapp.com'}
        />
      )}
    </div>
  );
};

export default CardApplied;
