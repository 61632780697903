import React from 'react';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import ComparisonTable from 'components/Common/Tables/ComparisonTable';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { Debt, DebtRecommendation, DebtSummary, HardOfferData } from 'handlers/applicationData';
import { FlowComponentType } from 'routes/FlowRouter';
import { ReactComponent as LockOpenIcon } from 'images/lock-open.svg';
import { ButtonType } from 'components/Button/Button';
import { YourSavingsResult } from 'enums/FlowNextResults';

import styles from './YourSavings.module.scss';

const YourSavings = ({ handleNext }: FlowComponentType) => {
  const { hardOffer, debtSummary, maxLoanAmount } = useSelector(getApplicationData).application!;
  const { applied: cardApplied } = useSelector(getCardData);
  const { payOffTotal, planneryLoan, offerSummary } = hardOffer as HardOfferData;

  const totalDebt = Object.values(debtSummary as DebtSummary)
    .filter((debt: Debt) => debt.recommendation === DebtRecommendation.PayOff)
    .reduce((total, current) => total + current.totalBalance!, 0);

  const debtExceedsAvailableAmount = totalDebt > maxLoanAmount;

  const displaySubtitle = `See how we can help you pay ${
    debtExceedsAvailableAmount
      ? `up to ${formatMonetaryAmount(maxLoanAmount)} of your`
      : `your ${formatMonetaryAmount(totalDebt)} of`
  } high interest debt faster.`;

  let title = 'We’ve Unlocked Savings for You';

  if (cardApplied) {
    title = `Your Savings: ${formatMonetaryAmount(offerSummary.moneySaved)}`;
  }

  return (
    <div className={styles.container}>
      <FormNavigation title="Debt Consolidation" />
      <FormContainer className={styles.formContainer} title={title} subtitle={displaySubtitle}>
        <LockOpenIcon className={styles.lockOpen} />
      </FormContainer>

      <div className={styles.tableContainer}>
        <ComparisonTable
          comparisonLabel="vs"
          leftTable={{
            title: 'Current Debt',
            rows: [
              {
                amount: formatMonetaryAmount(payOffTotal.financeCharge),
                label: 'Total Interest',
              },
              {
                amount: `${payOffTotal.term} months`,
                label: 'to pay off',
              },
            ],
          }}
          rightTable={{
            title: 'With Plannery',
            rows: [
              {
                amount: formatMonetaryAmount(planneryLoan.financeCharge),
                label: 'Total Interest',
              },
              {
                amount: `${planneryLoan.term} months`,
                label: 'to pay off',
              },
            ],
          }}
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            handleNext(YourSavingsResult.Continue);
          }}
        >
          See how it works
        </Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(YourSavingsResult.BackToCard);
            }}
          >
            Not Now
          </Button>
        )}
      </div>
    </div>
  );
};

export default YourSavings;
