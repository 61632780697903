import React from 'react';
import clsx from 'clsx';
import { ReactComponent as PlusIcon } from 'images/plus-icon.svg';
import FlashaLogo from 'images/flasha-logo.png';
import FpmaLogo from 'images/fpma-logo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { AssociationBrandingName } from 'enums/AssociationBrandingName';
import { ReactComponent as LoveIcon } from 'images/love-icon.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { ReactComponent as PlanneryLogo } from 'images/plannery-logo-2.svg';

import styles from './Header.module.scss';

const Header = () => {
  const { association_branding: associationBranding } = useSelector((state: RootState) => state.preQualificationData);
  const { themeStyles } = useLayoutTheme();

  const renderAssociationBranding = () => {
    switch (associationBranding) {
      case AssociationBrandingName.Flasha:
        return (
          <>
            <PlusIcon className={styles.plus} />
            <img className={styles.logo} src={FlashaLogo} alt="Flasha" />
          </>
        );
      case AssociationBrandingName.Fpma:
        return (
          <>
            <PlusIcon className={styles.plus} />
            <img className={styles.logo} src={FpmaLogo} alt="FPMA" />
          </>
        );
      default:
        return undefined;
    }
  };

  return (
    <div className={clsx(styles.header, themeStyles.header)}>
      <div className={styles.topRow}>
        <PlanneryLogo className={clsx(styles.logo, themeStyles.logo)} />
        {renderAssociationBranding()}
      </div>
      <span className={clsx(styles.lovePhrase, themeStyles.lovePhrase)}>
        We <LoveIcon className={styles.loveIcon} /> Healthcare Workers
      </span>
    </div>
  );
};

export default Header;
