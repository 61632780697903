import React from 'react';
import { useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/FlowRouter';

import Button from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import { DebtSummary, LoanType } from 'handlers/applicationData';
import { getCardData } from 'selectors/getCardData';
import { formatNumber } from 'utils/formatNumber';

import { ReactComponent as PlanneryLogo } from 'images/plannery-icon.svg';
import { ReactComponent as ArrowIcon } from 'images/new-arrow-icon.svg';
import { ReactComponent as BankLogo } from 'images/bank-icon-small.svg';

import styles from './NextSteps.module.scss';

const getSubtitle = (debtSummary: DebtSummary) => {
  const personalLoanBalance = debtSummary[LoanType.PersonalLoan]?.totalBalance ?? 0;
  const creditCardBalance = debtSummary[LoanType.CreditCard]?.totalBalance ?? 0;

  let subtitle = `You have`;
  if (personalLoanBalance > 0) {
    subtitle += ` $${formatNumber(personalLoanBalance)} in personal loans`;
  }
  if (creditCardBalance > 0) {
    if (personalLoanBalance > 0) subtitle += ` and`;
    subtitle += ` $${formatNumber(creditCardBalance)} in credit cards`;
  }
  subtitle += `.`;
  return subtitle;
};

const NextSteps = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { debtSummary } = useSelector(getCardData);

  const subtitle = getSubtitle(debtSummary!);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Bring Your Balance to Plannery" subtitle={subtitle}>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <BankLogo />
            </div>
            <div className={styles.text}>Your Balance</div>
          </div>
          <ArrowIcon className={styles.arrowIcon} />
          <div className={styles.item}>
            <div className={styles.icon}>
              <PlanneryLogo />
            </div>
            <div className={styles.text}>Plannery</div>
          </div>
        </div>

        <p className={styles.subtext}>
          You can bring this balance to Plannery to access below market interest rates exclusively for healthcare
          professionals.
        </p>

        <Button onClick={handleNext}>Next</Button>
      </FormContainer>
    </>
  );
};

export default NextSteps;
