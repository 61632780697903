import { DebtSummary } from 'handlers/applicationData';

import { CardColor } from 'components/CardFlow/Customize/cardVersions';

import Api, { FetchMethodType } from './Api';
import { UtmTags } from './LoanOfferApi';

export interface CardApplyResponse {
  applicationId: string;
  borrowerId: string;
  referralLink: string;
  referredBy: string;
  applied: boolean;
  debtSummary: DebtSummary;
  waitListPosition: number;
}

export interface CardApplyData {
  email: string;
  firstName: string;
  lastName: string;
  credentials: string;
  phoneNumber: string;
  cardColor: CardColor;
  methodEntityId: string;
  cardReferredBy?: string;
  utm: UtmTags;
  httpReferrer?: string;
}

export interface CardApplyWithApplicationData {
  applicationId: string;
  httpReferrer?: string;
  referredBy?: string;
}

export interface CardApi {
  apply(data: CardApplyData): Promise<CardApplyResponse>;
  applyWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse>;
}

export default class CardRestApi extends Api implements CardApi {
  async apply(data: CardApplyData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>('/card/apply', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async applyWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>(`/card/apply/${data.applicationId}`, {
      method: FetchMethodType.POST,
      body: {
        referredBy: data.referredBy,
        httpReferrer: data.httpReferrer,
      },
    });
  }
}
